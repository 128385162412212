<template>
  <div
    v-loading="loading"
    element-loading-background="rgba(122, 122, 122, 0.1)"
    element-loading-text="Please wait"
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    style="z-index: 1"
  >
    <div class="card card-lead-by-sources">
      <!-- v-model:modelPolicyStatus="filter.policy_status" -->
      <div class="container container-lead bg-white border-radius-4">
          <div class="card-header p-0">
            <div
              class="
                d-flex
                align-items-center
                justify-content-between
                w-100
                spacing-b-15
              "
            >
              <div class="d-flex justify-content-between align-items-center">
                <div class="me-5"><h5>Generate Production Report for Date : </h5></div>
                <div class="form-select-custom form-select-align">
                  <div class="multiselect-dropdown-custom multiselect-simple">
                    <el-date-picker
                      format="DD/MM/YYYY"
                      value-format="YYYY-MM-DD"
                      placeholder="DD/MM/YYYY"
                      :clearable="false"
                      size="large"
                      v-model="filter.date"
                      @change="error = false"
                      :class="`${error ? 'red-border' : ''}`"
                    /> 
                  </div>
                </div>
                
                <div class="search-buttons-block d-flex align-items-center">
                    <div class="bd-highlight">
                      <button
                        type="button"
                        class="btn btn-light-info"
                        @click="generateReport"
                      >
                        Generate Report 
                      </button>
                    </div>
                  </div>

              </div>
            </div>
  
          </div>
        </div>

      <div class="card-body card-custom-spacing-tblf-10 bg-white">
        <div class="pt-0 p-0" v-if="productionReports && productionReports.length > 0">
          <div id="displayResponseData">
            <!-- class="table table-hover bg-bai-table-header table-bai-striped table-row-bordered border gy-7 gs-7 mb-0" -->
            <el-table :data="productionReports">
              <el-table-column
                v-for="list in production_report"
                :key="list.label"
                :fixed="list.isFixed"
                :width="list.width"
              >
                <template #header>
                  <span v-html="list.label" />
                </template>
                <template #default="scope">
                  <span v-if="list.isIndex">
                    {{ scope.$index + 1 }}
                  </span>
                  <span v-else-if="list.isFormat">
                    {{ common.setDateTimeOnly(scope.row[list.value]) }}
                  </span>
                  <span v-else-if="list.isCount">
                    <span
                      v-for="(n, index) in scope.row[list.value]"
                      :key="index"
                    >
                      {{ index + "(" + n + "), " }}
                    </span>
                  </span>
                  
                  <span 
                    v-else-if="list.isDownload">
                    <a target="_blank" :href="scope.row.url" class="btn btn-sm btn-light-info">
                      Download
                    </a>
                  </span>

                  <span
                    v-else
                    :class="[list.cn ]"
                   
                    v-html="scope.row[list.value]"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div v-else>
          <div v-if="loading">
            <span>Loading Information Please Wait...</span>
          </div>
          <div v-else class="container-fluid p-5">
            <div class="row">
              <div class="col-12">
                <el-empty description="No Records Found" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Report from "@/core/services/Callable/Report";
import { production_report } from "@/core/utils/reports";
import { store } from "@/store";
import moment from "moment";
import { common } from "@/store/stateless";
import Swal from "sweetalert2/dist/sweetalert2.js";

const filter = ref({
  date: "",
});


const loading = ref(false);
const error = ref(false);

const productionReports = computed(() => {
  return store.getters.getProductionReport;
});

onMounted(async () => {
  setCurrentPageBreadcrumbs("Production Report", [{ label: "Reports", value: "" }]);
  await Report.getProductionReport({});
});


const generateReport = async (value) => {
  if(!filter.value.date)
  {
    error.value = true;
    return;
  }
  loading.value = true;
  await Report.generateProductionReport(filter.value);
  setTimeout(() => {
          Swal.fire({
              text: "Production report generation request has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
        }, 2000);

        filter.value.date = ''
  loading.value = false;
};


</script>
<style>
  .text-bold {
    font-weight: bold !important; 
    color: rgb(34, 34, 97) !important;
  }
  .red-border {
    border : 1px solid red;
  }
</style>